<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Số điểm tài khoản theo ngày</h6>
              </template>
              <div class="filter-transaction">
                <filter-user @handleFilter="handleFilter" @handleExport="handleExport"></filter-user>
              </div>
              <b-table :fields="fields" :items="items" details-td-class="datatable-cell" hover table-class="datatable-table" tbody-tr-class="datatable-row" thead-class="datatable-head" thead-tr-class="datatable-row">
              </b-table>
              <div class="mt-3">
                <b-pagination-nav v-model="currentPage" :link-gen="createLinkPage" :number-of-pages="paginate.totalPage" use-router first-number last-number align="right"></b-pagination-nav>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>

    <alert-dialogue ref="confirmDialogue"></alert-dialogue>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import FilterUser from "@/view/pages/point/components/filterUser";
import CmsRepository from "@/core/repositories/cmsRepository";
import AlertDialogue from "../components/AlertModal";

export default {
  name: "List",
  mixins: [Common],
  components: { FilterUser, AlertDialogue},
  data() {
    return {
      fields: [
        {
          key: "user_id",
          label: "User ID",
        },
        {
          key: "phone",
          label: "Tài khoản",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "opening_point",
          label: "Số điểm đầu kỳ",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "ending_point",
          label: "Số điểm cuối kỳ",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "increase",
          label: "Phát sinh tăng",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "decrease",
          label: "Phát sinh giảm",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "change",
          label: "Thay đổi",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      filter: {},
      items: [],
      totalItem: 0,
      currentPage: this.$route.query.page ?? 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };
  },
  methods: {
    async getList() {
      try {
        this.$bus.$emit("show-loading", true);
        let params = this.filter;
        params.page = this.currentPage;
        params.limit = this.paginate.limit;

        this.items = [];

        let response = await CmsRepository.listDailyPoint(params);
        this.$bus.$emit("show-loading", false);
        let body = response.data;

        if (body.data === false) {
          alert("Có lỗi xảy ra, vui lòng thử lại");
        }

        this.totalItem = body.data.lists.total > 0 ? Number(body.data.lists.total).toLocaleString() : body.data.lists.total ;
        this.items = body.data.lists.data;

        let total = {
          id: this.totalItem,
          phone: "Tổng",
          opening_point: body.data.total.opening_point !== 0 ? Number(body.data.total.opening_point).toLocaleString() : 0,
          ending_point: body.data.total.ending_point !== 0 ? Number(body.data.total.ending_point).toLocaleString() : 0,
          increase: body.data.total.increase !== 0 ? Number(body.data.total.increase).toLocaleString() : 0,
          decrease: body.data.total.decrease !== 0 ? Number(body.data.total.decrease).toLocaleString() : 0,
          change: body.data.total.change !== 0 ? Number(body.data.total.change).toLocaleString() : 0
        };

        this.items.push(total);

        this.paginate.total = body.data.lists.total;
        this.paginate.totalPage = body.data.lists.last_page;
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    exportList: async function () {
      this.$bus.$emit("show-loading", true);
      let params = this.filter;
      params.page = this.currentPage;
      let response = await CmsRepository.listDailyExport(params);
      this.$bus.$emit("show-loading", false);

      if (response.data.error_code !== 0) {
        alert("Có lỗi xảy ra, vui lòng thử lại");
        return false;
      }

      let link = window.location.origin + "/#/reconcile/export-data";

      const cfm = await this.$refs.confirmDialogue.show({
        title: "Download File",
        message: "File đang được xử lý, bạn có thể tải xuống sau ít phút <a href='"+link+"' target='_blank'>TẠI ĐÂY</a>",
        okButton: "Ok",
      });
      if (!cfm) {
        return false;
      }
    },
    handleFilter(filter) {
      this.filter = filter;
      this.currentPage = 1;
      this.getList();
    },
    handleExport(filter) {
      this.filter = filter;
      this.exportList();
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
  },
  created() {
    this.getList();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Thông số dư ví" }]);
  },
  watch: {
    currentPage(newPage, oldPage) {
      if (newPage !== oldPage) {
        this.getList();
      }
    },
  },
};
</script>
