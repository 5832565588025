<template>
  <div>
    <div class="row mb-8">
      <div class="col-lg-6 mb-lg-0 mb-6">
        <label>Tìm kiếm:</label>
        <input type="text" class="form-control" v-model="filter.phone" placeholder="Số điện thoại"
        />
      </div>

      <div class="col-lg-6 mb-lg-0 mb-6">
        <label>Ngày tổng hợp:</label>
        <div class="input-daterange input-group" id="kt_datepicker">
          <input
            type="date"
            v-model="filter.date_from"
            class="form-control datatable-input"
            name="start"
            placeholder="Từ"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <i class="la la-ellipsis-h"></i>
            </span>
          </div>
          <input
            type="date"
            v-model="filter.date_to"
            class="form-control datatable-input"
            name="end"
            placeholder="Đến"
          />
        </div>
      </div>
    </div>

    <div class="row mb-8">
            <div class="col-lg-4 mb-lg-0 mb-6">
        <label>Phát sinh điểm:</label>
        <select v-model="filter.has_balance" class="form-control" id="account_type">
          <option value="0">Tất cả</option>
          <option value="1">Có</option>
          <option value="2">Không</option>
        </select>
      </div>
    </div>

    <div class="row mt-8">
      <div class="col-lg-12 text-right">
        <button
          class="btn btn-primary btn-primary--icon"
          @click="createLinkSearch()"
        >
          <span>
            <i class="la la-search"></i>
            <span>Tìm kiếm</span>
          </span>
        </button>
        &nbsp;
        <button class="btn btn-primary btn-primary--icon" @click="createExport()">
          <i class="la la-cloud-download"></i>
          <span>Xuất file</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "filterUser",
  data() {
    return {
      filter: {
        date_from: this.getTomorrow(),
        date_to: this.getTomorrow(),
        has_balance: null
      },
    };
  },
  components: {},
  mounted() {
    this.getTomorrow();
  },
  methods: {
    createLinkSearch() {
      if (!this.filter.date_from || !this.filter.date_to) {
        alert("Vui lòng chọn khoảng thời gian");
        return;
      }

      let today = this.getCurrentDay();
      let dateTo = new Date(this.filter.date_to).toISOString().split("T")[0];
      let dateFrom = new Date(this.filter.date_from).toISOString().split("T")[0];

      if (dateFrom > dateTo) {
        alert("Ngày kết bắt đầu không được lớn hơn ngày hết thúc");
        return;
      }

      if (dateFrom >= today || dateTo >= today) {
        alert("Ngày kết thúc phải nhỏ hơn ngày hiện tại");
        return;
      }

      if (!this.filter.phone) {
        delete this.filter.phone;
      }

      if (!this.filter.type) {
        delete this.filter.type;
      }

      this.$emit("handleFilter", this.filter);
    },
    createExport() {
      this.$emit("handleExport", this.filter);
    },
    getCurrentDay() {
      return new Date().toISOString().split("T")[0];
    },
    getTomorrow() {
      let today = new Date();
      let tomorrow = new Date();
      tomorrow.setDate(today.getDate() - 1);

      return tomorrow.toISOString().split("T")[0];
    },
  },
};
</script>
